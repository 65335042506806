import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiChip from '@material-ui/core/Chip'

const Top50Badge = styled(MuiChip)({
  color: 'white',
  backgroundColor: '#a265ba',
})

const Top20Badge = styled(MuiChip)({
  color: 'white',
  backgroundColor: '#009ade',
})

const Top10Badge = styled(MuiChip)({
  color: 'white',
  backgroundColor: '#27ae60',
})

const RankingBadge = ({rank, ...props}) => {
  if (!rank) return null

  const label = `Top ${rank}`

  let Component = MuiChip
  if (rank === 50) Component = Top50Badge
  if (rank === 20) Component = Top20Badge
  if (rank === 10) Component = Top10Badge

  return (
    <Component
      label={label}
      size="small"
      {...props}
    />
  )
}

export default RankingBadge
